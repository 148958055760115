import DataLayer from 'app/modules/datalayer';
import Logger from 'app/shared/logger';
import { getValue } from 'app/shared/utils';

const localStore = window.localStorage;

/**
 * ContentHistory keeps track of a user's viewed articles so they can be omitted from
 * Recirc and End of Content links.
 *
 * @exports contenthistory
 * @class
 *
 * @param {string} [namespace] Name of the localStorage key.
 */
const ContentHistory = function contenthistory(namespace) {
  this.contentNamespace = namespace || 'hrst.content.history';
  this.contentId = DataLayer.getValue('content.id');
  this.logger = new Logger('ContentHistory');

  this.addContent();
};

ContentHistory.prototype = {
  maxArticles: 50,
  /**
   * Stores the content Id of current article in localStorage
   */
  addContent: function addContent() {
    let history = [];

    // Check for id on content pages.
    if (!this.contentId) {
      this.logger.warn('contentId not found.');
      return;
    }

    // Fetch content history from localStorage.
    try {
      history = JSON.parse(localStore.getItem(this.contentNamespace));
    } catch (err) {
      this.logger.warn('Cannot parse content history. ', err);
    }

    if (!_.isArray(history)) {
      history = [];
    }

    // If we've already viewed this article before, don't add it.
    if (history.indexOf(this.contentId) > -1) {
      return;
    }

    // If the number of viewed articles are past the maximum, remove the most recent.
    if (history.length >= this.maxArticles) {
      history = history.slice(1, this.maxArticles);
    }

    // Add the content Id to our array in localStorage.
    history.push(this.contentId);

    // set item in localStorage
    localStore.setItem(this.contentNamespace, JSON.stringify(history));
  },

  /**
   * Returns a list of article ids that are unique to the viewer.
   *
   * @param {Object[]} data        list of article info to filter
   * @param {number}   numArticles Number of filtered articles to return
   * @param {bool}     backFill    Optional flag to append a chronological list of
   *                               articles that have already been viewed
   *
   * @return {Object[]}            List of article objects.
   */
  filterArticlesFromUserHistory: function filterArticlesFromUserHistory(
    data,
    numArticles,
    backFill,
  ) {
    let history = [];
    let candidates = [];
    let inContentHistory = [];
    let count = 0;
    let i;
    let len = data.length;
    let id;
    let index;

    if (!_.isArray(data)) {
      return candidates;
    }

    // type cast integer
    numArticles = ~~numArticles;

    // if no valid filter value has been provided, return unfiltered data
    if (numArticles <= 0) {
      return data;
    }

    // Fetch content history from localStorage.
    try {
      history = JSON.parse(localStorage.getItem(this.contentNamespace));
    } catch (err) {
      this.logger.warn('Cannot read user content history. ', err);
    }

    // If no articles have been viewed, return the trimmed list of articles.
    if (!_.isArray(history)) {
      return data.slice(0, numArticles);
    }

    // Loop through data to find valid articles
    for (i = 0; i < len; i += 1) {
      // if we have enough candidates, stop
      if (count >= numArticles) {
        break;
      }

      // Get the id
      id = getValue('id', data[i]);
      index = history.indexOf(id);

      // Check if id is not in history, then add it
      if (id && index < 0) {
        candidates.push(data[i]);
        count += 1;
      } else {
        inContentHistory.push({
          data: data[i],
          order: index,
        });
      }
    }

    // If the backFill flag is set then we want to append
    // a chronological list of articles that have already been viewed
    if (backFill) {
      return candidates.concat(
        _.pluck(_.sortBy(inContentHistory, 'order'), 'data').slice(
          0,
          numArticles - candidates.length,
        ),
      );
    }

    // If we have candidates, return them.
    if (candidates.length) {
      return candidates;
    }

    // If there are no candidates, return the trimmed list of articles.
    return data.slice(0, numArticles);
  },
};

export default ContentHistory;
