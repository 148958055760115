import ContentHistory from 'app/shared/contenthistory';
import { getValue } from 'app/shared/utils';

/**
 * RelatedCollection is the collection used for retrieving
 * related module models.
 */
export default Backbone.Collection.extend({
  endpoint: 'ajax/endofcontent',

  /**
   * Initialize the collection
   *
   * @param {Array}    models  Models for collection
   * @param {Object} options Options overwrites
   *
   */
  initialize: function initialize(models, options) {
    // set filter value for response
    this.limit = getValue('limit', options, 2);
  },

  /**
   * parse function uses the response from successful fetch from server,
   * returns an array of model attributes that will be added to the collection
   *
   * @param  {Object} response Array of objects from a successful fetch
   *
   * @return {Object}          Objects that will be added as GenericModel attributes
   */
  parse: function parse(response) {
    let history = new ContentHistory();
    let articles = history.filterArticlesFromUserHistory(response, this.limit);

    if (!articles.length) {
      return [];
    }

    return { data: articles };
  },

  /**
   * determines if we've loaded enough articles.
   *
   * @return {Boolean} Whether or not there are enough articles fetched.
   */
  hasEnoughArticles: function hasEnoughArticles() {
    return !!this.at(0) && this.at(0).get('data').length >= this.limit;
  },
});
