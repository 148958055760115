import MediaLoader from 'app/modules/medialoader';
import DataLayer from 'app/modules/datalayer';
import EndOfContentCollection from 'app/collections/endofcontentcollection';
import GenericModelView from 'app/modelviews/genericmodelview';
import Logger from 'app/shared/logger';
import { ensureInstanceOf, getValue } from 'app/shared/utils';

/**
 * EndOfContent displays 2 related articles at the end of each article.
 * It fetches filtered articles from our EOC endpoint and loads them.
 *
 * **EOC content will not display if content testing is active on an article.**
 *
 * @class  endofcontent
 * @extends Backbone.View
 *
 * @param {Object} [options] Content options
 */
export default Backbone.View.extend(
  /** @lends endofcontent# */ {
    el: 'body',

    initialize: function initialize() {
      // store variables
      this.setVariables();

      // set up listeners
      this.setListeners();
    },

    setVariables: function setVariables() {
      this.overrideDefaults({
        // ensighten event that fires when content-testing will not appear
        displayEvent: 'render.apps.eoc',

        // Flag that gets set when we're clear to display EOC
        ensightenFlagName: 'HRST.article.showEOCLinks',

        // Model for eoc module items
        itemModel: GenericModelView.prototype.ModelTemplate,

        // View for eoc models
        itemView: GenericModelView,

        // Media loader for module media
        mediaLoader: null,

        // Selector for EOC div
        moduleSelector: '.end-of-content-module',

        // Template for eoc output
        moduleTemplate: '#eoc-module-template',

        // Class to indicate ad is available
        adClass: 'ad-active',

        // ad unit selector
        adSelector: '.eoc-ad',

        // Class to indiciate EOC views have rendered
        renderedClass: 'rendered',
      });

      this.$window = $(window);

      // set up logger
      this.logger = new Logger('EndOfContent');

      // collection of modules
      this.collection = new EndOfContentCollection([], { model: this.itemModel });

      // content id of the current article
      this.contentId = DataLayer.getValue('content.id');

      // get the EOC div
      this.$module = $(this.moduleSelector);

      // make sure we have a valid medialoader
      this.mediaLoader = ensureInstanceOf(this.mediaLoader, MediaLoader);

      // set initial state
      this.state = this.getInitialState();

      // set request object
      this.request = {};

      // eoc ad unit
      this.$eocAd = $(this.adSelector);

      // inserting event callback for teads ad insertion, callback toggles this.adClass
      window.teadsObj = _.extend(window.teadsObj || {}, {
        adAvailable: [this.toggleAd.bind(this, true)],
        noAd: [this.toggleAd.bind(this, false)],
        complete: [this.toggleAd.bind(this, false)],
      });
    },

    setListeners: function setListeners() {
      // Bind collection sync
      this.collection.on('sync', this.collectionSync.bind(this));

      // Bind ensighten event for OK to show EOC
      // fired by ensighten when content testing is not active
      // will display elements if fired after window load
      this.$window.one(this.displayEvent, this.handleEnsightenDisplay.bind(this));
    },

    /**
     * getInitialState returns the initial state of EndOfContent.
     * The display ability and the state of the loaded modules
     *
     * @return {Object} State object containing data about EOC modules
     */
    getInitialState: function getInitialState() {
      return {
        // check if EOC div is visible, or hidden by feature flag.
        isEnabled: !!this.$module.length,
        // ensighten flag for displaying modules.
        // TRUE if ensighten content testing is not active
        // Uses non-cached window to get latest HRST object.
        canDisplay: getValue(this.ensightenFlagName, window),
        // flag for fully loaded modules
        modulesLoaded: false,
      };
    },

    /**
     * collectionSync handles the collection sync event, creates views for EOC items
     * for the fetched models and appends them to the DOM.
     */
    collectionSync: function collectionSync() {
      // item placeholder
      let $renderedItems = $();
      // item view constructor
      let ItemView = this.itemView;

      if (!this.collection.hasEnoughArticles()) {
        this.logger.log('Not enough articles.');
        return;
      }

      // if modules have already been loaded
      if (this.state.modulesLoaded) {
        this.logger.log('No modules to load.');
        return;
      }

      this.logger.log('Sync', arguments);

      // Loop through models in the collection and
      // create views in our placeholder
      this.collection.each(
        function each(model) {
          // create a rendered view of the module
          let $view = $(
            new ItemView({
              model,
              templateSelector: this.moduleTemplate,
            }).render().el,
          );

          // Add to placeholder
          $renderedItems = $renderedItems.add($view);
        }.bind(this),
      );

      // Move rendered placeholder to module
      this.$module
        .attr('data-tracking-id', 'recirc-end-of-content')
        .prepend($renderedItems)
        .addClass(this.renderedClass);

      // We've loaded our modules
      this.state.modulesLoaded = true;
    },

    /**
     * handleEnsightenDisplay will peform a module fetch. This would
     * be run if this module is loaded after Ensighten.
     */
    handleEnsightenDisplay: function handleEnsightenDisplay() {
      this.state.canDisplay = true;
      // fetch modules
      this.fetchModules();
    },

    /**
     * fetchModules checks if modules have been loaded or if we are able
     * to display them. Then we call a fetch on our EndOfContentCollection.
     *
     * @return {boolean} False if no modules could be loaded. True if modules were fetched.
     */
    fetchModules: function fetchModules() {
      // check for a valid content id and that there are modules to load
      if (!this.contentId || !this.state.isEnabled || this.state.modulesLoaded) {
        this.logger.log('No modules to load.');
        return false;
      }

      // prevent a fetch if one is pending
      if (this.request.readyState > 0) {
        this.logger.log('There is a fetch in progress, exiting');
        return false;
      }

      // if we have a no-go from Ensighten
      if (!this.state.canDisplay) {
        this.logger.log('Moduled disabled by showEOCLinks in HRST object');
        return false;
      }

      // fetch the modules from our collection
      this.request = this.collection
        .fetch({
          data: {
            id: this.contentId,
          },
        })
        .fail(this.handleFetchError.bind(this));

      return true;
    },

    /**
     * handleFetchError removes placeholder modules if there was a problem with the fetch.
     */
    handleFetchError: function handleFetchError() {
      // log the error
      this.logger.error('Error fetching end of content modules.');

      // remove the modules
      this.$module.empty();
    },

    /**
     * toggleAd adds a the ad-active class which toggles ad visibilty
     * when activated along with the rendered class set in collectionSync line 167
     */
    toggleAd: function toggleAd(status) {
      this.$module.toggleClass(this.adClass, status);

      this.logger.log('EOC ad:' + status);
    },
  },
);
